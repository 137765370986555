<template lang="pug">
.default-textarea
    span {{ text }}
    textarea(spellcheck="false" :placeholder="placeholderText" :value="value" @input="input")
</template>

<script>
export default {
    name: "DefaultTextarea",
    props: {
        text: {
            type: String,
            default: ''
        },
        placeholderText: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    methods: {
        input(e) {
            return this.$emit("update:value", e.target.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.default-textarea {

    textarea {
        width: 263px;
        height: 102px;
        padding: 10px;
        font-size: 12px;
        background-color: #fff;
        box-shadow: 0 3px 20px #0000000D;
        border: none;
        border-radius: 5px;
        resize: none;

        &:focus {
            outline: none;
        }
    }

    & > span {
        display: block;
        color: #434242;
        font-size: 12px;
        margin-bottom: 6px;
        padding-left: 4px;
    }

}
</style>
