<template lang="pug">
.post-description
	h2 ᲐᲦᲬᲔᲠᲐ
	.post-description-main-content
		DefaultSelect(
			title="მოლაპარაკების ტიპი"
			placeholder="მოლაპარაკების ტიპი"
			:options="[{title: 'ანაზღაურებადი', id: 1},{title: 'არაანაზღაურებადი', id: 2}]"
			:value="negotiationType"
			@filterUpdate="setType"
		)
		DefaultTextarea.post-description-textarea(text="აღწერა" placeholderText="მოკლე აღწერა" v-model:value="newPostData.description")
</template>

<script>
import {mapGetters} from "vuex";
import DefaultSelect from "../widgets/DefaultSelect.vue";
import DefaultTextarea from "../layout/DefaultTextarea.vue";

export default {
	name: "PostDescription",
	components: {DefaultTextarea, DefaultSelect},
	data() {
		return {
			newPostData: {
				description: "",
				is_salary: null
			}
		}
	},
	computed: {
		...mapGetters({
			post: "posts/post"
		}),
		negotiationType() {
			return this.newPostData.is_salary === null ? null : this.newPostData.is_salary ? 1 : 2
		}
	},
	watch: {
		post() {
			this.newPostData = {
				description: this.post.description,
				is_salary: this.post.is_salary
			}
		},
		newPostData: {
			handler() {
				this.$emit('setNewPostData', this.newPostData)
			},
			deep: true
		}
	},
	methods: {
		setType(selected) {
			this.newPostData.is_salary = selected.selectedId === 1;
		}
	}
}
</script>

<style lang="scss" scoped>
.post-description {
	h2 {
		font: normal normal bold 16px/19px 'Helvetica-Bold';
		letter-spacing: 0;
		color: #212121;
		margin-bottom: 15px;
	}

	.post-description-main-content {
		background: #FFFFFF 0 0 no-repeat padding-box;
		box-shadow: 0 3px 20px #00000029;
		border-radius: 5px;
		padding: 20px;
		height: calc(100% - 34px);

		:deep(.default-textarea) {
			span {
				font: normal normal bold 14px/19px 'Helvetica-Bold' !important;
				letter-spacing: 0;
				color: #212121 !important;
			}

			textarea {
				font-size: 16px;
				color: #6E6E6E;
				padding: 12px 16px;
				width: 100%;
				height: 420px;
			}
		}
	}
}
</style>
