<template lang="pug">
.post-categories
	h2 ᲙᲐᲢᲔᲒᲝᲠᲘᲔᲑᲘ
	.categories-cont
		DefaultMultiSelect(:options="tags" placeholder="კატეგორია" @filterUpdate="setCategories" :value="newPostData.tags")
	.date-range
		h2 ᲒᲐᲓᲐᲦᲔᲑᲔᲑᲘᲡ ᲠᲔᲘᲜᲯᲘ
		.date-range-inputs
			DefaultDatePickers(@filterUpdate="setDate" :firstDate="newPostData.start_date" :secondDate="newPostData.end_date")
</template>

<script>
import {mapGetters} from "vuex";

import DefaultMultiSelect from "../widgets/DefaultMultiSelect.vue";
import DefaultDatePickers from "../widgets/DefaultDatePickers.vue";

export default {
	name: "PostCategories",
	components: {DefaultDatePickers, DefaultMultiSelect},
	data() {
		return {
			newPostData: {
				tags: [],
				start_date: "",
				end_date: ""
			}
		}
	},
	computed: {
		...mapGetters({
			tags: "posts/tags",
			post: "posts/post"
		})
	},
	watch: {
		post() {
			const tagIdListing = []
			if (this.post.tags && this.post.tags.length) {
				this.post.tags.forEach(el => tagIdListing.push(el.id))
			}
			this.newPostData = {
				tags: tagIdListing,
				start_date: this.post.start_date,
				end_date: this.post.end_date
			}
		}
	},
	methods: {
		setCategories(e) {
			let element = this.newPostData.tags.findIndex(el => el === e.selectedId)
			if (element !== -1) {
				this.newPostData.tags.splice(element, 1)
			} else {
				this.newPostData.tags.push(e.selectedId)
			}
			this.$emit("setNewPostData", this.newPostData)
		},
		setDate(e) {
			this.newPostData.start_date = e.value[0]
			this.newPostData.end_date = e.value[1]
			this.$emit("setNewPostData", this.newPostData)
		}
	}
}
</script>

<style lang="scss" scoped>
.post-categories {
	h2 {
		font: normal normal bold 16px/19px 'Helvetica-Bold';
		letter-spacing: 0;
		color: #212121;
		margin-bottom: 15px;
	}

	.categories-cont {
		padding: 0 20px 20px;
	}

	.date-range {
		h2 {
			font: normal normal bold 16px/19px 'Helvetica-Bold';
			letter-spacing: 0;
			color: #212121;
			margin-bottom: 15px;
		}

		.date-range-inputs {
			padding: 0 20px 20px;
		}
	}
}
</style>
